import conversations from "../img/conversation_001.png";
import { Image, Container } from "react-bootstrap";
const ContactForm = () => {
  return (
    <div id="Contact" className="p-3 text-white bg-dark">
      <Image src={conversations} fluid className="mx-auto d-block" />
      <Container className="p-3">
        Contact Information <br />
        Need to get ahold of us? Great! Email us at contact@riseofthepodcast and
        tell us a story, share a joke, offer some advice, or just say hi! We'd
        love to hear from you!
      </Container>
    </div>
  );
};
export default ContactForm;
