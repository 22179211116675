import logo from "../img/rotp_logo_1000.png";
import whoAreThey from "../img/whoAreThey_001.png";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Image } from "react-bootstrap";
console.log(window);
let Twitch = window.Twitch;
console.log(Twitch);
const Listen = () => {
  useEffect(() => {
    new Twitch.Embed("twitch-embed", {
      width: 854,
      height: 480,
      channel: "riseofthepodcast",
    });
  }, []);
  return (
    <div id="Listen" className="p-3 text-white bg-dark">
      <Container className="p-3">
        <div id="twitch-embed"></div>
      </Container>
    </div>
  );
};
export default Listen;
