import getToKnow from "../img/getToKnow_001.png";
import logo from "../img/rotp_logo_1000.png";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
const About = () => {
  return (
    <div id="About">
      <Image src={getToKnow} fluid className="mx-auto d-block" />
      <Container className="p-3">
        <p>
          Jeremy is obsessed with Star Wars, video games, and cars. He dabbles
          in a little bit of everything, and definitely loves a long-winded
          story!
        </p>
        <p>
          Kara loves Lord of the Rings, Star Wars, and drawing. She enjoys
          learning new things, and has her hands full keeping Jeremy in line!
        </p>
      </Container>
    </div>
  );
};
export default About;
