import logo from "../img/rotp_logo_1000.png";
import whoAreThey from "../img/whoAreThey_001.png";

import Container from "react-bootstrap/Container";
import { Image } from "react-bootstrap";
const Introduction = () => {
  return (
    <div id="Home" className="p-3 text-white bg-dark">
      <Image src={logo} fluid className="mx-auto d-block" />
      <Container className="p-3">
        <br />
        <h1> Hello everybody,</h1>
        <br /> and welcome to Rise of the Podcast. Our goal is to be a place for
        everyone to come and share what they love. We'd be stoked if you wanted
        to join us for an episode, or if you wanted to make a video together.
        Part of the joy of loving something is sharing it with others, and
        that's what we want to do here. We love a lot of things: Star Wars, Lord
        of the Rings, Marvel, LEGO, filmmaking, video games, VR, 3D printing,
        photography, cosplaying, and conventions. Our time-lapse videos of our
        builds are a fun way to get the LEGO experience in a condensed format.
        Each video includes awesome shots of the kits, multi-cam angles,
        time-lapses, and funny moments that take place during the build. You can
        play along and test your LEGO brick knowledge, Star Wars trivia, and so
        much more as we laugh, draw, guess, and fail at all the things we love.
        Check out our weekly podcast where we talk about nerdy stuff, meet and
        talk with new people, and share cool stuff!
      </Container>
    </div>
  );
};
export default Introduction;
