import { Container } from "react-bootstrap";
const PodcastArchive = () => {
  return (
    <div id="Archive" className="p-3 ">
      <Container className="p-3">
        Podcast Archive <br />
        Want to dig up the past and see our old videos? This is the place! Look
        around, you'll probably find something that you like!
      </Container>
    </div>
  );
};
export default PodcastArchive;
