import logo from "./img/rotp_logo_1000.png";
import "./App.css";
import Introduction from "./pages/Introduction";
import Navigation from "./pages/Navigation";
import PodcastArchive from "./pages/PodcastArchive";
import About from "./pages/About";
import ContactForm from "./pages/ContactForm";
import Listen from "./pages/Listen";
import FalconAnimation from "./pages/FalconAnimation";
function App() {
  return (
    <body
      data-bs-spy="scroll"
      data-bs-target="#navbar-rotp"
      data-bs-offset="0"
      class="scrollspy"
      tabindex="0"
    >
      <div className="App">
        <header className="App-header">
          <Navigation />
        </header>
        <Introduction />
        <FalconAnimation />
        <About />
        <Listen />
        <PodcastArchive />
        <ContactForm />
      </div>
    </body>
  );
}

export default App;
