import AnimateCC from "react-adobe-animate";
import { Container } from "react-bootstrap";

const FalconAnimation = () => {
  return (
    <div className="text-white bg-black">
      <Container>
        <AnimateCC animationName="rotp_001" />
      </Container>
    </div>
  );
};

export default FalconAnimation;
