import { Navbar, NavDropdown, Container, Nav } from "react-bootstrap";
import logo from "../img/rotp_logo_1000.png";
const Navigation = () => {
  return (
    <Navbar id="navbar-rotp" fixed="top" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src={logo}
            width="100"
            height="76"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>

        <Nav className="me-auto">
          <Nav.Link href="#Home">Home</Nav.Link>
          <Nav.Link href="#About">About</Nav.Link>
          <Nav.Link href="#Listen">Listen</Nav.Link>
          <Nav.Link href="#Archive">Archive</Nav.Link>
          <Nav.Link href="#Contact">Contact</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default Navigation;
